import {HttpClientModule , HttpClient , HttpErrorResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
// import { Property, Pagination, Location } from './app.models';
// import { Property } from './app.models';
import { User } from '../app/core/models/user'; 
import { Customer } from "./admin/customers/allcustomers/customer.model";
import { Sellers } from "./admin/sellers/allsellers/sellers.model";
import { HttpHeaders } from '@angular/common/http';


@Injectable()

export class HttpRequest {
    ServerIP : string = "titanium-motors.co.il";

    constructor (private _http: HttpClient){
    }


    public Authenticate(username: string, password: string) {
      var url = "https://" + this.ServerIP + "/php/CRM_Authenticate.php";
      // console.log(url);
      return this._http.post<User>(url,'{"username":"'+username+'","password":"'+password+'"}')
    }

  // ----------------------------------------------------------------------------------

    public getAllLocalCustomers(token: string){
        var url = "https://" + this.ServerIP + "/php/CRM_getAllLocalCustomers.php";
        // console.log(token);
        return this._http.post<Customer>(url,'{"token":"'+token+'"}')
    }


    public addLocalCustomers(token: string, data: any) {
      var url = "https://" + this.ServerIP + "/php/CRM_addLocalCustomers.php";
      console.log("sending request");
      return this._http.post(
        url,
        '{"token":"'+token+'", "First_Name":"'+data['First_Name']+'", "Last_Name":"'+data['Last_Name']+'", "Cellphone":"'+data['Cellphone']+'", "Id_Number":"'+data['Id_Number']+'", "Email":"'+data['Email']+'", "Address_City":"'+data['Address_City']+'", "Address_Street_Name":"'+data['Address_Street_Name']+'", "Address_Street_Number":"'+data['Address_Street_Number']+'", "Address_Apartment_Number":"'+data['Address_Apartment_Number']+'", "Car_Manufacture":"'+data['Car_Manufacture']+'", "Car_Model":"'+data['Car_Model']+'", "Car_Year":"'+data['Car_Year']+'", "Car_Price":"'+data['Car_Price']+'", "Remarks":"'+data['Remarks']+'"}'
        )
    }

    public updateLocalCustomers(token: string, cid: number, data: any) {
      var url = "https://" + this.ServerIP + "/php/CRM_updateLocalCustomers.php";
      // console.log("sending request");
      return this._http.post(
        url,
        '{"token":"'+token+'", "cid": "'+cid+'", "First_Name":"'+data['First_Name']+'", "Last_Name":"'+data['Last_Name']+'", "Cellphone":"'+data['Cellphone']+'", "Id_Number":"'+data['Id_Number']+'", "Email":"'+data['Email']+'", "Address_City":"'+data['Address_City']+'", "Address_Street_Name":"'+data['Address_Street_Name']+'", "Address_Street_Number":"'+data['Address_Street_Number']+'", "Address_Apartment_Number":"'+data['Address_Apartment_Number']+'", "Car_Manufacture":"'+data['Car_Manufacture']+'", "Car_Model":"'+data['Car_Model']+'", "Car_Year":"'+data['Car_Year']+'", "Car_Price":"'+data['Car_Price']+'", "Remarks":"'+data['Remarks']+'"}'
        )
    }

    public deleteLocalCustomers(token: string, cid: number) {
      var url = "https://" + this.ServerIP + "/php/CRM_deleteLocalCustomers.php";
      // console.log("sending request");
      return this._http.post(
        url,
        '{"token":"'+token+'", "cid": "'+cid+'"}'
        )
    }
  // ----------------------------------------------------------------------------------
    public getAllSellers(token: string){
      var url = "https://" + this.ServerIP + "/php/CRM_getAllSellers.php";
      // console.log(token);
      return this._http.post<Sellers>(url,'{"token":"'+token+'"}')
    }


    public addSeller(token: string, data: any) {
      var url = "https://" + this.ServerIP + "/php/CRM_addSeller.php";
      console.log("sending request");
      return this._http.post(
        url,
        '{"token":"'+token+'", "Buisness_Name":"'+data['Buisness_Name']+'","Buisness_Id_Number":"'+data['Buisness_Id_Number']+'", "Buisness_Address_City":"'+data['Buisness_Address_City']+'", "Buisness_Address_Street_Name":"'+data['Buisness_Address_Street_Name']+'", "Buisness_Address_Street_Number":"'+data['Buisness_Address_Street_Number']+'", "Buisness_Address_Apartment_Number":"'+data['Buisness_Address_Apartment_Number']+'" , "First_Name":"'+data['First_Name']+'", "Last_Name":"'+data['Last_Name']+'", "Cellphone":"'+data['Cellphone']+'", "Id_Number":"'+data['Id_Number']+'", "Email":"'+data['Email']+'", "Address_City":"'+data['Address_City']+'", "Address_Street_Name":"'+data['Address_Street_Name']+'", "Address_Street_Number":"'+data['Address_Street_Number']+'", "Address_Apartment_Number":"'+data['Address_Apartment_Number']+'", "Remarks":"'+data['Remarks']+'"}'
        )
    }

    public updateSeller(token: string, cid: number, data: any) {
      var url = "https://" + this.ServerIP + "/php/CRM_updateSeller.php";
      console.log("sending request !!!!!!!!!!!");
      return this._http.post(
        url,
        '{"token":"'+token+'", "cid": "'+cid+'", "Buisness_Name":"'+data['Buisness_Name']+'","Buisness_Id_Number":"'+data['Buisness_Id_Number']+'", "Buisness_Address_City":"'+data['Buisness_Address_City']+'", "Buisness_Address_Street_Name":"'+data['Buisness_Address_Street_Name']+'", "Buisness_Address_Street_Number":"'+data['Buisness_Address_Street_Number']+'", "Buisness_Address_Apartment_Number":"'+data['Buisness_Address_Apartment_Number']+'" , "First_Name":"'+data['First_Name']+'", "Last_Name":"'+data['Last_Name']+'", "Cellphone":"'+data['Cellphone']+'", "Id_Number":"'+data['Id_Number']+'", "Email":"'+data['Email']+'", "Address_City":"'+data['Address_City']+'", "Address_Street_Name":"'+data['Address_Street_Name']+'", "Address_Street_Number":"'+data['Address_Street_Number']+'", "Address_Apartment_Number":"'+data['Address_Apartment_Number']+'", "Remarks":"'+data['Remarks']+'"}'
        )
    }

    public deleteSeller(token: string, cid: number) {
      var url = "https://" + this.ServerIP + "/php/CRM_deleteSeller.php";
      // console.log("sending request");
      return this._http.post(
        url,
        '{"token":"'+token+'", "cid": "'+cid+'"}'
        )
    }

  // ----------------------------------------------------------------------------------
 //   public getCar(id){
 //     var url = "https://" + this.ServerIP + "/php/getCar.php?id=" + id;
 //     return this._http.get<Property>(url)
 //     .pipe(map(res => res));
 //  }

   public getFeaturedCars(){
    var url = "https://" + this.ServerIP + "/php/getFeaturedCars.php";
    return this._http.get(url)
    .pipe(map(res => res));
  }

  public getCarOfTheDay(){
    var url = "https://" + this.ServerIP + "/php/getCarOfTheDay.php";
    return this._http.get(url)
    .pipe(map(res => res));
  }

  public getAgentID(id){
    var url = "https://" + this.ServerIP + "/php/getAgentID.php?id=" + id;
    return this._http.get(url)
    .pipe(map(res => res));
 }

 public getAgentByID(id){
  var url = "https://" + this.ServerIP + "/php/getAgentByID.php?id=" + id;
  return this._http.get(url)
  .pipe(map(res => res));
}

public getRelatedCars(manufacture, model, id){
  var url = "https://" + this.ServerIP + "/php/getRelatedCars.php?manufacture=" + manufacture + "&model=" + model + "&id=" + id;
  // console.log(url);
  return this._http.get(url)
  .pipe(map(res => res));
}

public getManufactures(){
  var url = "https://" + this.ServerIP + "/php/getManufactures.php";
  return this._http.get(url)
  .pipe(map(res => res));
}

public getCarModels(){
  var url = "https://" + this.ServerIP + "/php/getCarModels.php";
  return this._http.get(url)
  .pipe(map(res => res));
}

public getCarModelsByManufacture(manufacture){
  var url = "https://" + this.ServerIP + "/php/getCarModelsByManufacture.php?manufacture=" + manufacture;
  // console.log(url);
  return this._http.get(url)
  .pipe(map(res => res));
}
  
    errorHandler(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
          'קרתה תקלת רשת, אנא נסו שנית');
      }

}