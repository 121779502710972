import { Injectable } from "@angular/core";
import { HttpErrorResponse  } from "@angular/common/http";
import { HttpRequest } from 'src/app/http.service';
import { BehaviorSubject, Observable, of, throwError  } from "rxjs";
import { map, catchError  } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private httpRequest: HttpRequest) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    console.log("Sending authentication");
    return this.httpRequest.Authenticate(username, password)
    .pipe(
      catchError(this.handleError)
    )
    .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // console.log("user:" + user);
          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          // console.log("auth debug 2");
          return user;
        }),
      );
  }

    logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    return of({ success: false });
  }

  private handleError(error: HttpErrorResponse) {
    // console.log("error status=" + error.status);
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else if (error.status === 401 || error.status === 500) {
        return throwError(() => new Error('שם משתמש או סיסמא שגויים, אנא נסו שנית'));
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
